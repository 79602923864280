// import React, { useState, useEffect } from "react";

// const SeparateDatePicker = ({ onChange, placeholderText, selectedDate }) => {
//   const [dateInputs, setDateInputs] = useState({
//     day: "",
//     month: "",
//     year: "",
//   });

//   // Initialize with selected date if provided
//   useEffect(() => {
//     if (selectedDate instanceof Date) {
//       setDateInputs({
//         day: selectedDate.getDate().toString().padStart(2, "0"),
//         month: (selectedDate.getMonth() + 1).toString().padStart(2, "0"),
//         year: selectedDate.getFullYear().toString(),
//       });
//     }
//   }, [selectedDate]);

//   const validateAndUpdateDate = (newInputs) => {
//     const { day, month, year } = newInputs;

//     // Only proceed if all fields are filled
//     if (day && month && year && year.length === 4) {
//       const newDate = new Date(
//         parseInt(year),
//         parseInt(month) - 1,
//         parseInt(day)
//       );

//       // Validate if it's a real date (e.g., not Feb 31)
//       if (
//         newDate.getFullYear() === parseInt(year) &&
//         newDate.getMonth() === parseInt(month) - 1 &&
//         newDate.getDate() === parseInt(day)
//       ) {
//         onChange(newDate);
//       }
//     }
//   };

//   const handleInputChange = (field, value) => {
//     // Remove any non-numeric characters
//     const numericValue = value.replace(/\D/g, "");

//     let validatedValue = numericValue;

//     // Apply field-specific validation
//     switch (field) {
//       case "day":
//         validatedValue = numericValue.slice(0, 2);
//         if (parseInt(validatedValue) > 31) validatedValue = "31";
//         break;
//       case "month":
//         validatedValue = numericValue.slice(0, 2);
//         if (parseInt(validatedValue) > 12) validatedValue = "12";
//         break;
//       case "year":
//         validatedValue = numericValue.slice(0, 4);
//         const currentYear = new Date().getFullYear();
//         if (
//           validatedValue.length === 4 &&
//           parseInt(validatedValue) > currentYear
//         ) {
//           validatedValue = currentYear.toString();
//         }
//         break;
//       default:
//         break;
//     }

//     const newInputs = { ...dateInputs, [field]: validatedValue };
//     setDateInputs(newInputs);
//     validateAndUpdateDate(newInputs);
//   };

//   const handleKeyDown = (field, e) => {
//     // Handle backspace
//     if (e.key === "Backspace" && e.target.value === "") {
//       e.preventDefault();
//       const fields = ["day", "month", "year"];
//       const currentIndex = fields.indexOf(field);
//       if (currentIndex > 0) {
//         const prevField = document.querySelector(
//           `input[name=${fields[currentIndex - 1]}]`
//         );
//         prevField?.focus();
//       }
//     }
//   };

//   const handleInput = (field, e) => {
//     const maxLengths = { day: 2, month: 2, year: 4 };
//     if (e.target.value.length >= maxLengths[field]) {
//       const fields = ["day", "month", "year"];
//       const currentIndex = fields.indexOf(field);
//       if (currentIndex < fields.length - 1) {
//         const nextField = document.querySelector(
//           `input[name=${fields[currentIndex + 1]}]`
//         );
//         nextField?.focus();
//       }
//     }
//   };

//   return (
//     <div className="flex flex-nowrap items-center space-x-2 w-full">
//       {/* Day Input */}
//       <div className="w-20">
//         {/* <label className="block text-sm font-medium text-gray-700 mb-1">
//           Day
//         </label> */}
//         <input
//           type="text"
//           name="day"
//           value={dateInputs.day}
//           onChange={(e) => handleInputChange("day", e.target.value)}
//           onKeyDown={(e) => handleKeyDown("day", e)}
//           onInput={(e) => handleInput("day", e)}
//           placeholder="DD"
//           className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           maxLength={2}
//         />
//       </div>

//       {/* Month Input */}
//       <div className="w-20">
//         {/* <label className="block text-sm font-medium text-gray-700 mb-1">
//           Month
//         </label> */}
//         <input
//           type="text"
//           name="month"
//           value={dateInputs.month}
//           onChange={(e) => handleInputChange("month", e.target.value)}
//           onKeyDown={(e) => handleKeyDown("month", e)}
//           onInput={(e) => handleInput("month", e)}
//           placeholder="MM"
//           className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           maxLength={2}
//         />
//       </div>

//       {/* Year Input */}
//       <div className="">
//         {/* <label className="block text-sm font-medium text-gray-700 mb-1">
//           Year
//         </label> */}
//         <input
//           type="text"
//           name="year"
//           value={dateInputs.year}
//           onChange={(e) => handleInputChange("year", e.target.value)}
//           onKeyDown={(e) => handleKeyDown("year", e)}
//           onInput={(e) => handleInput("year", e)}
//           placeholder="YYYY"
//           className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           maxLength={4}
//         />
//       </div>
//     </div>
//   );
// };

// export default SeparateDatePicker;

// import React, { useState, useEffect } from "react";

// const SeparateDatePicker = ({ onChange, placeholderText, selectedDate }) => {
//   const [dateInputs, setDateInputs] = useState({
//     day: "",
//     month: "",
//     year: "",
//   });

//   useEffect(() => {
//     if (selectedDate instanceof Date) {
//       setDateInputs({
//         day: selectedDate.getDate().toString().padStart(2, "0"),
//         month: (selectedDate.getMonth() + 1).toString().padStart(2, "0"),
//         year: selectedDate.getFullYear().toString(),
//       });
//     }
//   }, [selectedDate]);

//   const validateAndUpdateDate = (newInputs) => {
//     const { day, month, year } = newInputs;
//     if (day && month && year && year.length === 4) {
//       const newDate = new Date(
//         parseInt(year),
//         parseInt(month) - 1,
//         parseInt(day)
//       );
//       if (
//         newDate.getFullYear() === parseInt(year) &&
//         newDate.getMonth() === parseInt(month) - 1 &&
//         newDate.getDate() === parseInt(day)
//       ) {
//         onChange(newDate);
//       }
//     }
//   };

//   const handleInputChange = (field, value) => {
//     const numericValue = value.replace(/\D/g, "");
//     let validatedValue = numericValue;

//     switch (field) {
//       case "day":
//         validatedValue = numericValue.slice(0, 2);
//         if (parseInt(validatedValue) > 31) validatedValue = "31";
//         break;
//       case "month":
//         validatedValue = numericValue.slice(0, 2);
//         if (parseInt(validatedValue) > 12) validatedValue = "12";
//         break;
//       case "year":
//         validatedValue = numericValue.slice(0, 4);
//         const currentYear = new Date().getFullYear();
//         if (
//           validatedValue.length === 4 &&
//           parseInt(validatedValue) > currentYear
//         ) {
//           validatedValue = currentYear.toString();
//         }
//         break;
//       default:
//         break;
//     }

//     const newInputs = { ...dateInputs, [field]: validatedValue };
//     setDateInputs(newInputs);
//     validateAndUpdateDate(newInputs);
//   };

//   const handleKeyDown = (field, e) => {
//     if (e.key === "Backspace" && e.target.value === "") {
//       e.preventDefault();
//       const fields = ["day", "month", "year"];
//       const currentIndex = fields.indexOf(field);
//       if (currentIndex > 0) {
//         const prevField = document.querySelector(
//           `input[name=${fields[currentIndex - 1]}]`
//         );
//         prevField?.focus();
//       }
//     }
//   };

//   return (
//     <div className="flex flex-nowrap items-center space-x-2 w-full">
//       <div className="w-20">
//         <input
//           type="text"
//           name="day"
//           value={dateInputs.day}
//           onChange={(e) => handleInputChange("day", e.target.value)}
//           onKeyDown={(e) => handleKeyDown("day", e)}
//           placeholder="DD"
//           className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           maxLength={2}
//         />
//       </div>

//       <div className="w-20">
//         <input
//           type="text"
//           name="month"
//           value={dateInputs.month}
//           onChange={(e) => handleInputChange("month", e.target.value)}
//           onKeyDown={(e) => handleKeyDown("month", e)}
//           placeholder="MM"
//           className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           maxLength={2}
//         />
//       </div>

//       <div className="w-24">
//         <input
//           type="text"
//           name="year"
//           value={dateInputs.year}
//           onChange={(e) => handleInputChange("year", e.target.value)}
//           onKeyDown={(e) => handleKeyDown("year", e)}
//           placeholder="YYYY"
//           className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//           maxLength={4}
//         />
//       </div>
//     </div>
//   );
// };

// export default SeparateDatePicker;

import React, { useState, useEffect } from "react";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
  },
  inputContainer: {
    width: "80px", // for day and month inputs
  },
  yearContainer: {
    width: "96px", // wider for year input
  },
  input: {
    width: "100%",
    padding: "8px",
    border: "1px solid #d1d5db",
    borderRadius: "6px",
    outline: "none",
    fontSize: "14px",
  },
};

const SeparateDatePicker = ({ onChange, placeholderText, selectedDate }) => {
  const [dateInputs, setDateInputs] = useState({
    day: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    if (selectedDate instanceof Date) {
      setDateInputs({
        day: selectedDate.getDate().toString().padStart(2, "0"),
        month: (selectedDate.getMonth() + 1).toString().padStart(2, "0"),
        year: selectedDate.getFullYear().toString(),
      });
    }
  }, [selectedDate]);

  const validateAndUpdateDate = (newInputs) => {
    const { day, month, year } = newInputs;

    // Only proceed if all fields are filled
    if (day && month && year && year.length === 4) {
      // Create date object for validation
      const newDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );

      // Validate if it's a real date
      if (
        newDate.getFullYear() === parseInt(year) &&
        newDate.getMonth() === parseInt(month) - 1 &&
        newDate.getDate() === parseInt(day)
      ) {
        // Format date as YYYY-MM-DD for Odoo
        const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        onChange(formattedDate);
      }
    }
  };

  const handleInputChange = (field, value) => {
    const numericValue = value.replace(/\D/g, "");
    let validatedValue = numericValue;

    switch (field) {
      case "day":
        validatedValue = numericValue.slice(0, 2);
        if (parseInt(validatedValue) > 31) validatedValue = "31";
        break;
      case "month":
        validatedValue = numericValue.slice(0, 2);
        if (parseInt(validatedValue) > 12) validatedValue = "12";
        break;
      case "year":
        validatedValue = numericValue.slice(0, 4);
        break;

      default:
        break;
    }

    const newInputs = { ...dateInputs, [field]: validatedValue };
    setDateInputs(newInputs);
    validateAndUpdateDate(newInputs);
  };

  const handleKeyDown = (field, e) => {
    if (e.key === "Backspace" && e.target.value === "") {
      e.preventDefault();
      const fields = ["day", "month", "year"];
      const currentIndex = fields.indexOf(field);
      if (currentIndex > 0) {
        const prevField = document.querySelector(
          `input[name=${fields[currentIndex - 1]}]`
        );
        prevField?.focus();
      }
    }
  };

  const inputStyle = {
    ...styles.input,
    ":focus": {
      borderColor: "#3b82f6",
      boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.5)",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.inputContainer}>
        <input
          type="text"
          name="day"
          value={dateInputs.day}
          onChange={(e) => handleInputChange("day", e.target.value)}
          onKeyDown={(e) => handleKeyDown("day", e)}
          placeholder="DD"
          style={inputStyle}
          maxLength={2}
        />
      </div>

      <div style={styles.inputContainer}>
        <input
          type="text"
          name="month"
          value={dateInputs.month}
          onChange={(e) => handleInputChange("month", e.target.value)}
          onKeyDown={(e) => handleKeyDown("month", e)}
          placeholder="MM"
          style={inputStyle}
          maxLength={2}
        />
      </div>

      <div style={styles.yearContainer}>
        <input
          type="text"
          name="year"
          value={dateInputs.year}
          onChange={(e) => handleInputChange("year", e.target.value)}
          onKeyDown={(e) => handleKeyDown("year", e)}
          placeholder="YYYY"
          style={inputStyle}
          maxLength={4}
        />
      </div>
    </div>
  );
};

export default SeparateDatePicker;
